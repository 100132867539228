
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ProfileComponent from '@/components/profile/ProfileComponent.vue';
import Routes from '@/router/Routes';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    ProfileComponent
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = computed(() => store.getters['userModule/currentUser']);

    function onLogout() {
      router.push({
        name: Routes.LOGIN,
        query: { refresh: 'true' }
      });
    }

    return { onLogout, user };
  }
});
