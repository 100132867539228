
import { defineComponent, PropType } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ProfilePhotoComponent from '@/components/profile/ProfilePhotoComponent.vue';
import { User } from '../../models/User';
import { UserRole, UserRoleLabels } from '../../models/UserRoles';

export default defineComponent({
  components: {
    ButtonComponent,
    ProfilePhotoComponent
  },
  emits: ['logout'],
  props: {
    user: {
      type: Object as PropType<User>
    }
  },
  setup(_, { emit }) {
    function onLogout() {
      emit('logout');
    }

    function userRoleLabel(role: UserRole) {
      return UserRoleLabels.get(role);
    }

    return {
      onLogout,
      userRoleLabel
    };
  }
});
